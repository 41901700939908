import config from '../config';
import axios from 'axios';
import { showError } from '../utils';
import { store } from '../store';

export function checkIdAndGetMagicLink(body) {
  return axios
    .post(`${config.apiBaseUrl}/partners/generate-magic-link-star-health`, body)
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function checkIdandPhoneToGetMagicLink(body) {
  return axios
    .post(
      `${config.apiBaseUrl}/partners/v2/generate-magic-link-star-health`,
      body
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getWellnessPolicies(authToken) {
  const headers = {
    Authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/star-health/rewards/get-user-policies`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAvailableRewards(authToken, policyId, memberId) {
  const headers = {
    Authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/star-health/rewards/get-available-rewards?userPolicyId=${policyId}&memberId=${memberId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCompletedRewards(authToken, policyId, memberId) {
  const headers = {
    Authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/star-health/rewards/get-completed-rewards?userPolicyId=${policyId}&memberId=${memberId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getDocumentType(authToken, id) {
  const headers = {
    Authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/star-health/rewards/get-reward-details?rewardId=${id}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getDocumentRelated(authToken, id) {
  const headers = {
    Authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/star-health/rewards/get-document-related-to-type?documentTypeId=${id}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitActivityDocuments(authToken, data) {
  const headers = {
    Authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/star-health/rewards/submit-reward-request`,
      data,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCompletedActivityStatus(authToken, id) {
  const headers = {
    Authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/star-health/rewards/get-completed-reward-details?rewardReqId=${id}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCompletedRewardsAlert(authToken) {
  const headers = {
    Authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/star-health/rewards/get-completed-reward-alerts`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function hraSubmitReward() {
  const { authToken } = store.getState().user;

  const headers = {
    authorization: authToken,
  };

  const body = {};

  return axios
    .post(`${config.apiBaseUrl}/star-health/rewards/submit-hra-reward`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function fitnessSyncReward() {
  const { authToken } = store.getState().user;

  const headers = {
    authorization: authToken,
  };

  const body = {};

  return axios
    .post(
      `${config.apiBaseUrl}/star-health/rewards/submit-fintess-sync-reward`,
      body,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getConfig(authToken) {
  const headers = {
    authorization: authToken,
  };

  const body = {};

  return axios
    .get(`${config.apiBaseUrl}/star-health/rewards/config`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      return responseBody;
    })
    .catch((err) => {
      throw err;
    });
}

export function getAllPatientsForUser(authToken, serviceType) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/star-health/cashless-booking/getUsersPolicies?serviceType=${serviceType}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.status === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getAllInsuredMembers(authToken, policyId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/star-health/rewards/get-insured-members?policyId=${policyId}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      return responseBody;
    })
    .catch((err) => {
      throw err;
    });
}

export function verifyOtpForStarHealth(phone, otp) {
  const body = {
    phone,
    otp,
    platform: 'STAR-HEALTH',
    service: 'OPD',
  }

  return axios
    .post(`${config.apiBaseUrl}/new-auth/verfiy-otp-for-star-phc`, body)
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'otpVerified') return responseBody;
      else {
        const error = new Error('Invalid OTP');
        error.message = 'Invalid OTP';
        throw error;
      }
    })
    .catch((err) => {
      console.log(err);
      showError(err);
      throw err;
    });
}